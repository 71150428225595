@import '~bootstrap/scss/bootstrap.scss';

body {
    background: #e6e5de !important;
}

@font-face {
    font-family: Astro;
    src: url('./fonts/astro.ttf');
}
@font-face {
    font-family: Roboto;
    src: url('./fonts/Roboto-Regular.ttf');
}

.bg-purple {
    background-color: #052727;
}

.card {
    background-color: transparent !important;
    border: none !important;
}

h1 {
    color: #052727;
    font-family: Astro;
}
h2 {
    color: #052727;
    font-weight: bold !important;
}
p,
li,
h3 {
    font-family: Roboto;
    color: #222;
    text-align: center;
}

.container img {
    width: 300px;
}

.img-fluid {
    height: 100%;
    image-rendering: pixelated;
}

@media (min-width: 768px) {
    .col-md-2 {
        flex: 0 0 auto;
        width: 20%;
    }
}
